export { }

const categories = document.querySelectorAll(".m-category")

categories.forEach((category) => {
  const links = category.querySelectorAll(".m-category-item.--has-submenu")

  links.forEach((link) => {
    link.addEventListener("mouseenter", () => { category.classList.add("--active") })
    link.addEventListener("mouseleave", () => { category.classList.remove("--active") })
  })
})
