const initGallery = (gallerySelector: string, galleryLinkSelector: string, postfix = "I") => {
  const galleries = document.querySelectorAll(gallerySelector);
  let galleryId = `gallery-${postfix}`

  galleries.forEach((gallery) => {
    const links = gallery.querySelectorAll(galleryLinkSelector);

    links.forEach(link => {
      link.setAttribute("data-fancybox", galleryId);
    });
    galleryId += postfix;
  });
}

initGallery(".gallery", ".gallery-icon a");
initGallery(".wp-block-gallery", ".blocks-gallery-item a", "W");
