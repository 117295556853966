export { }

const headerTopContent = document.querySelector(".m-header-top__grid")
const mainNavBottom = document.querySelector(".m-nav__bottom")
const headerTopContainer = document.querySelector(".m-header-top__container")

const mobileQuery = window.matchMedia("(max-width: 61.999em)")

if (headerTopContent && mainNavBottom && headerTopContainer) {
  const handleMobileChange = (mediaQueryList: MediaQueryListEvent | MediaQueryList) => {
    if (mediaQueryList.matches) {
      // Its Mobile
      mainNavBottom.prepend(headerTopContent)
    } else {
      // Its Desktop
      headerTopContainer.prepend(headerTopContent)
    }
  }

  mobileQuery.addEventListener("change", handleMobileChange)
  handleMobileChange(mobileQuery)
}
