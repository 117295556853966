// Added submenu button, in order to dropdown submenu
// Style sub-menu__button element to your needs

const mobileQuery = window.matchMedia("(max-width: 61.999em)")

const itemsWithSubmenu = document.querySelectorAll(".menu-item-has-children")
itemsWithSubmenu.forEach((item) => {
  item.insertAdjacentHTML("beforeend", `<span class="sub-menu__button"></span>`)

  const submenuButton = item.querySelector(".sub-menu__button")
  const submenu = item.querySelector(":scope > .sub-menu")

  const handleSubmenu = (event: Event) => {
    if (!mobileQuery.matches) return

    event.preventDefault()
    submenu?.classList.toggle("--active")
    submenuButton?.classList.toggle("--active")
  }

  submenuButton?.addEventListener("click", handleSubmenu)

})
