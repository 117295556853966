const selectLinkItems = document.querySelectorAll<HTMLElement>('.i-select-link');

selectLinkItems.forEach(selectLink => {
  const links = selectLink.querySelectorAll<HTMLElement>('.i-select-link__option')
  const selectOnly = selectLink.classList.contains('--select-only');

  const setSelectedValue = (value: string) => {
    if (!value) return;

    selectLink.dataset.value = value;
  }

  links.forEach(link => {
    link.addEventListener('click', (e) => {
      if(link.classList.contains('--active')) {
        e.preventDefault();
        selectLink.classList.toggle('--opened');
      }
      else {
        links.forEach(foreignLink => foreignLink.classList.remove('--active'))
        if (selectOnly) e.preventDefault();
        link.classList.add('--active');
        selectLink.classList.remove('--opened');
        setSelectedValue(link.dataset.value ?? "");
        selectLink.dispatchEvent(new Event('change'));
      }
    })

    if(link.classList.contains('--active')) {
      setSelectedValue(link.dataset.value ?? "");
    }
  })

  window.addEventListener('click', (e) => {
    const targetElement = e.target as HTMLElement;

    if (!selectLink.contains(targetElement)) {
      selectLink.classList.remove('--opened');
    }
  })
})
