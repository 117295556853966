const dropdowns = document.querySelectorAll<HTMLElement>('.i-user-dropdown');
const mobileQuery = window.matchMedia("(max-width: 61.999em)")
const initTriggers = () => {
  const triggers = document.querySelectorAll('[data-user-dropdown-target]')

  triggers.forEach((trigger) => {
    const dropdownTarget = document.getElementById(trigger.getAttribute("data-user-dropdown-target") ?? "")

    if (dropdownTarget === null) return

    trigger.addEventListener("click", (event) => {
      if (mobileQuery.matches) return;

      event.preventDefault()
      openDropdown(dropdownTarget);
    })
  })
}

const openDropdown = (dropdown : HTMLElement) => {
  closeAll();
  dropdown.classList.add('--open');
}

const closeDropdown = (dropdown : HTMLElement) => {
  dropdown.classList.remove('--open');
}

const closeAll = () => {
  dropdowns.forEach(dropdown => {
    closeDropdown(dropdown)
  })
}

document.addEventListener('click', (e) => {
  const target = e.target as Element;
  if (!(target?.closest('.i-user-dropdown') || target?.closest('[data-user-dropdown-target]'))) { closeAll() }
})

initTriggers();
