export { }

const fieldsWrapper = document.querySelectorAll<HTMLElement>(".f-wrap")

fieldsWrapper.forEach(inputField => {
  const inputWrapper = inputField.querySelector<HTMLElement>(".f-input, .f-textarea")
  const input = inputField.querySelector<HTMLInputElement>(".f-input__input, .f-textarea__input")
  const label = inputField.querySelector<HTMLLabelElement>(".f-label")

  if (!label || !input) return

  const hasPlaceholder = !!input.getAttribute("placeholder")

  const addActive = () => {
    label.classList.add("--active")
    inputWrapper?.classList.add("--active")
  }

  const removeActive = () => {
    label.classList.remove("--active")
    inputWrapper?.classList.remove("--active")
  }

  const handleActive = () => { input?.value === "" && !hasPlaceholder ? removeActive() : addActive() }

  input?.addEventListener("focusout", handleActive)
  input?.addEventListener("focus", addActive)
  input?.addEventListener('input', handleActive);
  input?.addEventListener('change', handleActive);
  handleActive() // Init handle
})
