export { }

const sections = document.querySelectorAll(".s-seo")

sections.forEach(section => {
  const button = section.querySelector<HTMLElement>(".s-seo__button")
  if (!button) return

  const buttonTitle = button.querySelector(".btn__title")

  const open = () => {
    section.classList.add("--active")
    if (buttonTitle && button.dataset.close) { buttonTitle.innerHTML = button.dataset.close }
  }

  const close = () => {
    section.classList.remove("--active")
    if (buttonTitle && button.dataset.open) { buttonTitle.innerHTML = button.dataset.open }
  }

  const toggle = () => { section.classList.contains("--active") ? close() : open() }

  button.addEventListener("click", toggle)


})
