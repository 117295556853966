import {debounce} from "../../../js/debounce/debounce";

const headerSearchForms = document.querySelectorAll<HTMLElement>('.f-header-search');

headerSearchForms.forEach(search => {
  const button = search.querySelector<HTMLElement>('.f-header-search__button');

  button?.addEventListener('click', (e) => {
    e.preventDefault();
    search.classList.add('--open');
  });

  const fetchUrl = search.dataset.whispererSearchLink ?? "";
  if(!fetchUrl) return;

  const input = search.querySelector<HTMLInputElement>('input');

  const setResult = async () => {
    const resultWrapper = search.querySelector<HTMLElement>('.f-header-search__dropdown')
    const template = search.querySelector<HTMLTemplateElement>('.f-header-search__result-item')

    if(!resultWrapper || !template) return;

    resultWrapper.classList.add('--loading');

    const response = await fetch(`${fetchUrl}/${input.value}`, {
      method: "POST",
    })

    if (response.ok) {
      const data = (await response.json()) as searchData;
      if (!data) {
        resultWrapper.classList.remove('--loading');
        return;
      }

      resultWrapper.innerHTML = "";
      if(data.products.length <= 0) resultWrapper.innerHTML = `Žádné výsledky`;
      data.products.forEach(product => {
        let productItemHtml = template.innerHTML;
        productItemHtml = productItemHtml
          .replaceAll('__TITLE__', product.name)
          .replaceAll('__LINK__', product.link)
          .replaceAll("__PRICE__", product.price)
          .replaceAll("__SRC__", product.image)
          .replaceAll("__PRICE_WITHOUT_VAT__", product.priceWithoutVat)
        resultWrapper.innerHTML += productItemHtml
      })
    }

    resultWrapper.classList.remove('--loading');
  }

  input?.addEventListener('input', debounce(setResult, 500))
  input?.addEventListener('input', () => {
    if (input.value) search.classList.add('--filled')
    else search.classList.remove('--filled')
  })
  input?.addEventListener('focus', () => {
    if (input.value) search.classList.add('--focus')
  })
  input?.addEventListener('blur', () => {
    search.classList.remove('--focus')
  })
});

document.addEventListener("click", (event) => {
  if (!(event.target as Element)?.closest('.f-header-search')) {
    headerSearchForms.forEach(search => {
      search.classList.remove('--open');
    });
  }
});

type searchData = {
  products: product[]
}

type product = {
  name: string
  code: string
  ean: string
  price: string
  priceWithoutVat: string
  image: string
  link: string
}
