const filters = document.querySelectorAll(".s-filters")

filters.forEach(filter => {
  const form = filter.querySelector<HTMLFormElement>(".s-filters__form")
  const toggler = filter.querySelector<HTMLElement>(".--js-filters-toggler")
  const clearAllButton = filter.querySelector(".--js-clear-filters")
  const body = filter.querySelector<HTMLElement>(".s-filters__body")
  const checkboxes = filter.querySelectorAll<HTMLInputElement>("input[type=checkbox]")
  const clearButtons = filter.querySelectorAll<HTMLElement>(".se-multi-checkbox__clear")

  const uncheckAll = () => { checkboxes.forEach(checkbox => checkbox.checked = false) }
  const hideClearButtons = () => { clearButtons.forEach(button => button.classList.remove("--active")) }
  const removeSelectedFilters = () => { filter.querySelectorAll(".g-selected-filters .i-selected-filter").forEach(filter => filter.remove()) }

  toggler?.addEventListener("click", () => {
    const title = toggler.querySelector(".btn__title")

    const open = () => {
      body?.classList.remove("--hidden")
      if (title && toggler.dataset.close) {
        title.innerHTML = toggler.dataset.close
      }
    }

    const close = () => {
      body?.classList.add("--hidden")
      if (title && toggler.dataset.open) {
        title.innerHTML = toggler.dataset.open
      }
    }

    body?.classList.contains("--hidden") ? open() : close()
  })

  clearAllButton?.addEventListener("click", (event) => {
    event.preventDefault()
    uncheckAll()
    hideClearButtons()
    removeSelectedFilters()
    form?.dispatchEvent(new Event('filter'))
  })


  form?.addEventListener("filter", () => {
    console.log("lets filter");
  })
})
