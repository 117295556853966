export { }

const dropdowns = document.querySelectorAll(".i-header-dropdown")

dropdowns.forEach(dropdown => {
  const itemsSelector = ".i-dropdown-product.--delete-single-compare"
  const clearButton = dropdown.querySelector(".--js-clear-compare")
  const section = dropdown.querySelector(".s-dropdown-product")

  clearButton?.addEventListener("click", (event: Event) => {
    event.preventDefault()
    dropdown.querySelectorAll(itemsSelector).forEach(item => { item.remove() })
    section?.classList.add("--empty")
    dropdown.classList.add("--open")
    dropdown.classList.remove("--fill")
  })

})

