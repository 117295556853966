// image wraped with link -> added data-facnybox attribute to envoke fancybox (exept gallery items)
// example:

//<a href="images/content/sample-02.jpg">
// <img src="" data-src="https://source.unsplash.com/300x368/?giraffe" alt="">
//</a>

// Converts to:

//<a href="images/content/sample-02.jpg" data-fancybox>
// <img src="" data-src="https://source.unsplash.com/300x368/?giraffe" alt="">
//</a>


const links = document.querySelectorAll('.entry-content a:not(.entry-content .i-gallery__icon a)')

links.forEach((link) => {
  const image = link.querySelector(':scope[href$=".jpg"] > img, :scope[href$=".png"] > img, :scope[href$=".jpeg"] > img')

  if (image !== null) {
    link.setAttribute("data-fancybox", "")

    if (image.classList.contains("aligncenter")) {
      image.classList.remove("aligncenter")
      link.classList.add("aligncenter")
    }
  }
})

