const iframes = document.querySelectorAll(".entry-content iframe")

iframes.forEach((iframe) => {
  const iframeSrc = iframe.getAttribute("src")

  if (iframeSrc === null) return

  if (iframeSrc.indexOf("youtube") !== -1) {
    iframe.classList.add("embed-responsive-item")
    iframe.removeAttribute("height")
    iframe.removeAttribute("width")

    const iframeWrap = document.createElement("div")
    iframeWrap.classList.add("ratio", "ratio-16x9")

    iframe.parentNode?.insertBefore(iframeWrap, iframe)
    iframeWrap.appendChild(iframe)
  }

})

