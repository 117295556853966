export { }

const graderTabs = document.querySelectorAll(".i-grader-tab")

const getTabsCount = () => document.querySelectorAll(".i-grader-tab").length

graderTabs.forEach((tab) => {
  const clear = tab.querySelector(".i-grader-tab__count")

  clear?.addEventListener("click", () => {
    const contentTarget = tab.getAttribute("data-tab-target") ?? ""
    const content = document.querySelector(`[data-content-id="${contentTarget}"]`)

    if (tab.classList.contains("--active")) {
      const newActiveTab = document.querySelector<HTMLElement>(".i-grader-tab:not(.--active)")
      newActiveTab?.dispatchEvent(new Event('click'))
    }

    tab.remove()

    if (content === null) return

    content.remove()

    if (getTabsCount() === 0) {
      document.querySelector(".s-grader")?.classList.add("--empty")
    }
  })
})
