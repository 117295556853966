const tabsGroup = document.querySelectorAll(".js-tabs")

tabsGroup.forEach(tabGroup => {
  const tabItems = tabGroup.querySelectorAll(".js-tabs__tab-item")
  const contents = tabGroup.querySelectorAll(".js-tabs__panel")

  const closeAllPanels = () => contents.forEach(content => content.classList.remove("--active"))
  const resetAllTabItems = () => tabItems.forEach(link => link.classList.remove("--active"))

  tabItems.forEach(tabItem => {
    tabItem.addEventListener("click", (event) => {
      event.preventDefault()

      if (tabItem.classList.contains("--active")) return

      const targetId = tabItem.getAttribute("data-tab-target") ?? ""
      const targetPanel = tabGroup.querySelector(`[data-content-id="${targetId}"]`)

      if (targetPanel === null) return

      closeAllPanels()
      resetAllTabItems()
      targetPanel.classList.add("--active")
      tabItem.classList.add("--active")

    })
  })
})
