export {}

const passwords = document.querySelectorAll(".f-input.--password")

passwords.forEach((password) => {
  const toggle = password.querySelector(".f-input__toggle")
  const input = password.querySelector<HTMLInputElement>(".f-input__input[type='password']")

  if (!input) return

  toggle?.addEventListener("click", () => {
    toggle.classList.toggle("--active")
    input.type = input.type === "password" ? "text" : "password"
  })
})
