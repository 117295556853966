(function () {

  //Functions
  function asideNavMobile() {
    const widgetCategoriesTitle = document.querySelectorAll(".widget_categories .widgettitle");
    if (widgetCategoriesTitle) {
      for (let i = 0; i < widgetCategoriesTitle.length; i++) {
        let category = widgetCategoriesTitle[i];
        category.addEventListener("click", function () {
          //Up to tablet portrait - up to 991px
          if (window.matchMedia("(max-width: 61.938em)").matches) {
            category.classList.toggle("js-open");
            if (category.parentNode.querySelector("ul")) {
              category.parentNode.querySelector("ul").classList.toggle("js-open");
            }
            if (category.parentNode.querySelector("form")) {
              category.parentNode.querySelector("form").classList.toggle("js-open");
            }

          }
        })
      }
    }

    const widgetNavmMenu = document.querySelectorAll(".widget_nav_menu .widgettitle");
    if (widgetNavmMenu) {
      for (let i = 0; i < widgetNavmMenu.length; i++) {
        let navMenu = widgetNavmMenu[i];
        navMenu.addEventListener("click", function () {
          //Up to tablet portrait - up to 991px
          if (window.matchMedia("(max-width: 61.938em)").matches) {
            navMenu.classList.toggle("js-open");
            navMenu.parentNode.querySelector("ul").classList.toggle("js-open");
          }
        })
      }
    }
    const widgetPages = document.querySelectorAll(".widget_pages .widgettitle");
    if (widgetPages) {
      for (let i = 0; i < widgetPages.length; i++) {
        let page = widgetPages[i];
        page.addEventListener("click", function () {
          //Up to tablet portrait - up to 991px
          if (window.matchMedia("(max-width: 61.938em)").matches) {
            page.classList.toggle("js-open");
            page.parentNode.querySelector("ul").classList.toggle("js-open");
          }
        })
      }
    }

  }

  function asideNavResizeReset() {
    const widgetCategoriesTitle = document.querySelectorAll(".widget_categories .widgettitle");
    if (widgetCategoriesTitle) {
      for (let i = 0; i < widgetCategoriesTitle.length; i++) {
        let category = widgetCategoriesTitle[i];
        if (category.classList.contains("js-open")) {
          category.classList.remove("js-open")
        }
        if (category.parentNode.querySelector("ul") && category.parentNode.querySelector("ul").classList.contains("js-open")) {
          category.parentNode.querySelector("ul").classList.remove("js-open");
        }
        if (category.parentNode.querySelector("form")) {
          if (category.parentNode.querySelector("form").classList.contains("js-open")) {
            category.parentNode.querySelector("form").classList.remove("js-open");
          }
        }
      }
    }

    const widgetNavmMenu = document.querySelectorAll(".widget_nav_menu .widgettitle");
    if (widgetNavmMenu) {
      for (let i = 0; i < widgetNavmMenu.length; i++) {
        let navMenu = widgetNavmMenu[i];
        if (navMenu.classList.contains("js-open")) {
          navMenu.classList.remove("js-open")
        }
        if (navMenu.parentNode.querySelector("ul") && navMenu.parentNode.querySelector("ul").classList.contains("js-open")) {
          navMenu.parentNode.querySelector("ul").classList.remove("js-open");
        }
      }
    }
    const widgetPages = document.querySelectorAll(".widget_pages .widgettitle");
    if (widgetPages) {
      for (let i = 0; i < widgetPages.length; i++) {
        let page = widgetPages[i];
        if (page.classList.contains("js-open")) {
          page.classList.remove("js-open")
        }
        if (page.parentNode.querySelector("ul") && page.parentNode.querySelector("ul").classList.contains("js-open")) {
          page.parentNode.querySelector("ul").classList.remove("js-open");
        }
      }
    }
  }

  asideNavMobile();


  //Resize
  window.addEventListener("resize", function () {
    if (window.matchMedia("(min-width: 62em)").matches) {
      asideNavResizeReset();

    }
  })
})();
