export { }

const setProducts = document.querySelectorAll(".i-set-product")

setProducts.forEach((setProduct) => {
  const header = setProduct.querySelector(".i-set-product__parameters-header")
  const table = setProduct.querySelector(".i-set-product__table")

  if (!header || !table) return

  header.addEventListener("click", () => {
    table.classList.toggle("--active")
    header.classList.toggle("--active")
  })
})
