

const counterElements = document.querySelectorAll(".c-counter")

counterElements.forEach((counter) => {
  const minus = counter.querySelector(".c-counter__button.--minus")
  const plus = counter.querySelector(".c-counter__button.--plus")
  const input = counter.querySelector<HTMLInputElement>(".c-counter__input")
  const valueVisual = counter.querySelector<HTMLElement>(".c-counter__value")

  if (!input) return

  plus?.addEventListener("click", () => {
    if (input.max !== input.value) {
      input.stepUp()
      input.dispatchEvent(new Event('change'))
    }
    if (valueVisual) {
      valueVisual.innerHTML = input.value ?? ""
    }
  })

  minus?.addEventListener("click", () => {
    if (input.min < input.value) {
      input.stepDown()
      input.dispatchEvent(new Event('change'))
    }
    if (valueVisual) {
      valueVisual.innerHTML = input.value ?? ""
    }
  })

})
