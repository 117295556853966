const switchFieldsInputs = document.querySelectorAll<HTMLInputElement>(".f-switch__input");

switchFieldsInputs.forEach(switchFieldInput => {
  const targets = document.querySelectorAll<HTMLElement>(switchFieldInput.dataset?.switchTarget);
  const closestTarget = switchFieldInput.closest<HTMLElement>(switchFieldInput.dataset?.switchClosestTarget)
  const className = switchFieldInput.dataset?.switchTargetClassName;

  switchFieldInput.addEventListener("change", () => {
    addClassOnCheck(switchFieldInput);
  })

  const addClassOnCheck = (input : HTMLInputElement) => {
    if(input?.checked) {
      targets.forEach( target => {
        target.classList.add(className);
      });

      closestTarget?.classList.add(className)
    }

    else {
      targets.forEach( target => {
        target.classList.remove(className);
      });

      closestTarget?.classList.remove(className)
    }
  }

  addClassOnCheck(switchFieldInput);
})



