
export { }

const dropdowns = document.querySelectorAll(".i-header-dropdown")

dropdowns.forEach(dropdown => {
  const itemsSelector = ".i-dropdown-product.--delete-single-compare"
  const items = dropdown.querySelectorAll(itemsSelector)
  const section = dropdown.querySelector(".s-dropdown-product")

  const getItemsCount = () => dropdown.querySelectorAll(itemsSelector).length

  items.forEach(item => {
    const deleteButton = item.querySelector(".i-dropdown-product__action")

    if (!deleteButton) return

    deleteButton.addEventListener("click", () => {
      item.remove()

      if (getItemsCount() === 0) {
        section?.classList.add("--empty")
        dropdown.classList.remove("--fill")
      }
    })
  })

})
