const mainNav = document.querySelector(".m-nav")
const overlay = document.querySelector(".c-overlay")

const closers = document.querySelectorAll(".--js-main-nav-closer")
const openers = document.querySelectorAll(".--js-main-nav-opener")
const togglers = document.querySelectorAll(".--js-main-nav-toggler")

const closeMenu = (event: Event) => {
  event.preventDefault()
  mainNav?.classList.remove("--open")
  overlay?.classList.remove("--show")
  document.documentElement.style.setProperty("overflow", null)
}

const openMenu = (event: Event) => {
  event.preventDefault()
  mainNav?.classList.add("--open")
  overlay?.classList.add("--show")
  document.documentElement.style.setProperty("overflow", "hidden")
}

const toggleMenu = (event: Event) => { mainNav?.classList.contains("--open") ? closeMenu(event) : openMenu(event) }

closers.forEach(closer => { closer.addEventListener("click", closeMenu) })
openers.forEach(opener => { opener.addEventListener("click", openMenu) })
togglers.forEach(toggler => { toggler.addEventListener("click", toggleMenu) })
