export { }

const mainNav = document.querySelector(".main-nav")

if (mainNav) {
  const items = mainNav.querySelectorAll(".m-category-item.--has-submenu")

  items.forEach(item => {
    const dropdown = item.querySelector(".m-category-item__dropdown")
    dropdown?.addEventListener("click", () => { item.classList.toggle("--open") })
  })
}
