export { }

const graderSections = document.querySelectorAll(".s-grader")

graderSections.forEach(section => {

  const clearButton = section.querySelector(".s-grader__clear-button")

  clearButton?.addEventListener("click", () => {
    const tabGroup = section.querySelector(".g-grader-tab")
    const contents = section.querySelectorAll(".g-grader")

    tabGroup?.remove()
    contents.forEach(content => { content.remove() })

    section.classList.add("--empty")
  })
})
