export { }

const toggleItems = document.querySelectorAll(".i-content-toggle")

toggleItems.forEach((toggleItem) => {
  const toggle = toggleItem.querySelector(".i-content-toggle__header")
  const content = toggleItem.querySelector(".i-content-toggle__content")

  if (!toggle || !content) return
  const inputElements = content.querySelectorAll<HTMLInputElement>("input, select, textarea")

  const handleInputs = () => { inputElements.forEach(inputElement => inputElement.disabled = !toggleItem.classList.contains("--active")) }

  handleInputs() // Init

  toggle.addEventListener("click", () => {
    toggleItem.classList.toggle("--active")
    handleInputs()
  })
})
