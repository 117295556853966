import smoothscroll from "smoothscroll-polyfill"

smoothscroll.polyfill()

const scrollUpElement = document.getElementById("scroll-up")

if (scrollUpElement !== null) {

  const offsetFromTop = Number(scrollUpElement.getAttribute("data-offset-from-top")) ?? 100 // if not set default is 100px

  const isInLimit = () => document.body.scrollTop > offsetFromTop || document.documentElement.scrollTop > offsetFromTop

  const scrollHandle = () => { scrollUpElement.classList.toggle("--active", isInLimit()) }

  const scrollTop = () => { window.scrollTo({ top: 0, behavior: "smooth" }) }

  window.onscroll = scrollHandle

  scrollUpElement.addEventListener("click", scrollTop)
}

