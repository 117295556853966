const removeWidthHeight = (selector: string): void => {
  const elements = document.querySelectorAll<HTMLElement>(selector)

  elements.forEach((element) => {
    element.removeAttribute("width");
    element.removeAttribute("height");
    element?.style.setProperty("width", null);
    element?.style.setProperty("height", null);
  });
}

removeWidthHeight(".entry-content table");
removeWidthHeight(".entry-content tr");
removeWidthHeight(".entry-content th");
removeWidthHeight(".entry-content td");

