import "./../css/style.css"

// wordpress
import "../components/wordpress"


// core
import "./../components/scroll-up/scroll-up"
import "./../components/modal/modal"
import "./../components/main-nav"
import "./../components/counter/counter"
import "./../components/pagination/pagination"

import "./cart-dropdown"
import "./compare-dropdown"
import "./tabs/tabs"


// sections
import "./../components/sections/filters-section/filters-section"
import "./../components/sections/seo-section/seo-section"
import "./../components/sections/grader-section/grader-section"

// groups
import "./../components/groups/grader-group/grader-group"

// fields
import "./../components/fields/input-field/input-field"
import "./../components/fields/input-field/input-field--password"
import "./../components/fields/switch-field/switch-field"

// forms
import "./../components/forms/header-search-form/header-search-form"

// items
import "./../components/items/header-dropdown-item/header-dropdown-item"
import "./../components/items/set-product-item/set-product-item"
import "./../components/items/content-toggle-item/content-toggle-item"
import "./../components/items/grader-tab-item/grader-tab-item"
import "./../components/items/menu-item/menu-item"
import "./../components/items/user-dropdown-item/user-dropdown-item"
import "./../components/items/order-info-item/order-info-item"
import "./../components/items/select-link-item/select-link-item"


// menus
import "./../components/menus/category-menu/category-menu"


(async () => {

  await import("./../components/base-header/base-header")

  async function loadFancybox() {
    if (document.querySelectorAll("[data-fancybox], [data-fancybox-gallery]").length) {
      await import("./fancybox/fancybox")
    }
  }
  loadFancybox()

})()

async function loadCart() {
  if (document.querySelectorAll(".--cart-layout").length) {
    await import("./cart")
  }
}
loadCart()

async function loadLazyload() {
  if (document.querySelectorAll("[data-src], [data-bg]").length) {
    const { lazyLoading } = await import("./lazyload/lazyload-init")
    window.lazyloading = lazyLoading // add lazyloading to global window object
  }
}
loadLazyload()

async function loadFormValidation() {
  if (document.querySelectorAll('form.js-needs-validation').length) {
    await import("./form-validation")
  }
}
loadFormValidation()

async function loadSwiper() {
  if (document.querySelectorAll(".swiper").length) {
    await import("./swiper/swiper-init")
  }
}
loadSwiper()

async function loadTippy() {
  if (document.querySelectorAll(".i-deliverity, [data-tippy-content]").length) {
    await import("./tippy")
  }
}
loadTippy()

async function loadSlimSelect() {
  if (document.querySelectorAll(".slim-select").length) {
    await import("./slim-select/slim-select")
  }
}
loadSlimSelect()

async function loadMultiCheckboxSelect() {
  if (document.querySelectorAll(".se-multi-checkbox").length) {
    await import("./../components/selects/multi-checkbox-select")
  }
}
loadMultiCheckboxSelect()

async function loadGraderItem() {
  if (document.querySelectorAll(".i-grader-slide").length) {
    await import("./../components/items/grader-slide-item/grader-slide-item")
  }
}
loadGraderItem()

async function loadCartOverviewBox() {
  if (document.querySelectorAll(".b-cart-overview").length) {
    await import("./../components/boxes/cart-overview-box/cart-overview-box")
  }
}

loadCartOverviewBox()

async function loadAlerts() {
  if (document.querySelectorAll(".i-alert").length) {
    await import("./../components/items/alert-item/alert-item")
  }
}
loadAlerts()

async function loadProductDetailPrice() {
  if (document.querySelectorAll(".pd-content").length) {
    await import("./product-detail-price/product-detail-price")
  }
}
loadProductDetailPrice()

async function loadCompanyFieldsetGroup() {
  if (document.querySelectorAll(".g-company-fieldset").length) {
    await import("../components/groups/company-fieldset-group/company-fieldset-group")
  }
}
loadCompanyFieldsetGroup()

async function loadDpdModal() {
  if (document.querySelectorAll(".m-dpd").length) {
    await import("../components/modals/dpd-modal/dpd-modal")
  }
}
loadDpdModal()
