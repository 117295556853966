export const sameHeight = (group: Element, targetsSelector: string, byRow = false) => {
  const targets = [...group.querySelectorAll<HTMLElement>(targetsSelector)]

  const getHighestElement = (elements = targets) => {
    const heights = elements.map(box => box.clientHeight)
    return Math.max(...heights)
  }

  const resetHeight = (elements = targets) => {
    elements.forEach(element => element.style.setProperty("height", null))
  }

  const updateHeight = (elements = targets) => {
    elements.forEach(element => element.style.setProperty("height", `${getHighestElement(elements)}px`))
  }

  const updateHeightByRow = () => {
    const rows = Array.from(new Set(targets.map(target => target.offsetTop)))
    const elementsCollection: HTMLElement[][] = []
    let targetsToFilter = targets

    rows.forEach((row) => {
      const elementsInSameRow = targets.filter(target => target.offsetTop === row)
      elementsCollection.push(elementsInSameRow)
      targetsToFilter = targetsToFilter.filter(item => !elementsInSameRow.includes(item))
    })

    elementsCollection.forEach(elements => updateHeight(elements))
  }

  const resizeObserver = new ResizeObserver(() => {
    resetHeight()
    byRow ? updateHeightByRow() : updateHeight()
  })

  resizeObserver.observe(group)
}
