export {}

const transitionLength = 300

export const openModal = (modal: HTMLElement) => {
  modal.classList.add("--open")
  modal.classList.remove("--hidden")
}

export const closeModal = (modal: HTMLElement) => {
  modal.classList.remove("--open")
  setTimeout(() => {
    modal.classList.add("--hidden")
  }, transitionLength)
}

const initTriggers = () => {
  const triggers = document.querySelectorAll("[data-modal-target]")

  triggers.forEach((trigger) => {
    const modalTarget = document.getElementById(trigger.getAttribute("data-modal-target") ?? "")

    if (modalTarget === null) return

    trigger.addEventListener("click", (event) => {
      event.preventDefault()
      openModal(modalTarget)
    })
  })
}

const initModals = () => {
  const modals = document.querySelectorAll<HTMLElement>(".c-modal")

  modals.forEach((modal) => {
    const closeButtons = document.querySelectorAll(".c-modal__close")

    modal.style.setProperty("--transtion-length", `${transitionLength}ms`)

    closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", (event) => {
        event.preventDefault()
        closeModal(modal)
      })
    })

    modal.addEventListener("click", (event) => {
      if (modal == event.target) {
        closeModal(modal)
      }
    })
  })
}

// In case needed reinitialize modals
// When something is re rendered
const init = () => {
  initTriggers()
  initModals()
}
export default init

init()
