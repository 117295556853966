import tippy from "tippy.js";

const orderInfoItems = document.querySelectorAll<HTMLElement>('.i-order-info');

orderInfoItems.forEach(orderInfo => {
  const copyButton = orderInfo.querySelector<HTMLElement>('.i-order-info__copy');

  if(!copyButton) return
  tippy(copyButton, {
    allowHTML: true,
    placement: 'bottom',
    content: `<div class="u-fz-12 u-fw-600">Zpráva úspěšně zkopírována</div>`,
    trigger: "click",
    offset: [0, 8],
    hideOnClick: false,
    onShow(instance) {
      setTimeout(() => {
        instance.hide();
      }, 2000);
    }
  })

  copyButton.addEventListener('click', (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(copyButton.dataset.url);
  })
})
